import { ToastContainer } from 'react-toastify';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppRouter } from './routers/AppRouter';
import { AppLayout } from './styles/common';

import "react-day-picker/style.css";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AppLayout>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
      />
      <AppRouter />
    </AppLayout>
    </LocalizationProvider>

  );
}

export default App;
