import React, { useCallback, useEffect, useState } from "react";
import * as z from 'zod';
import { useToast } from "../../common/hooks/useToast";
import { Button, Checkbox, colors, Container, FormControlLabel, Grid, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Media } from "./Media";
import { useMutation } from "@apollo/client";
import { MODERATE_BUSINESS_REVIEW } from "./reviews.mutation";

const schema = z.object({
  id: z.coerce.number(),
  content: z.string().min(100, { message: 'At least 100 characters are required' }),
  visible: z.boolean(),
  media: z.boolean().nullable(),
});

type SchemaProps = z.infer<typeof schema>;

export const EditReview: React.FC<PropsInterface> = (props: PropsInterface) => {
  const { success, error: toastError } = useToast();
  const [values, setValues] = useState<any>({
    id: props.review.id,
    content: props.review.content,
    visible: props.review.visible,
    media: props.review.Media?.url == null ? null : !!props.review.Media?.url,
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SchemaProps>({
    values,
    resolver: zodResolver(schema),
  });

  const [moderateReview, moderateResult] = useMutation(MODERATE_BUSINESS_REVIEW);

  const submitData = useCallback((values: any) => {
    console.log('values', values);
    moderateReview({ variables: { id: values.id, input: {
      visible: values.visible,
      content: values.content,
      media: values.media,
    } } });
  }, []);

  console.log('errors', errors);

  useEffect(() => {
    console.log('moderateResult', moderateResult);
    if (moderateResult.error) {
      toastError('Moderating the review failed. Please try again.');
    } else if (moderateResult.data) {
      success('Review moderated successfully');
      props.handleClose(1);
    }
  }, [moderateResult.error, moderateResult.data]);
  return (
    <Container maxWidth='lg' sx={{ width: '45rem', backgroundColor: colors.common.white, p: '2rem', border: '1px solid black' }}>
      <Grid item xs={12}>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit((d) => submitData(d))}
        >
          <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
            <Stack spacing={2}>
              <TextField InputLabelProps={{ shrink: true }} label="id" {...register('id')} disabled  />
              <TextField InputLabelProps={{ shrink: true }} label="Username" value={props.review.User?.username} disabled />
              <TextField InputLabelProps={{ shrink: true }} label="Busienss" value={props.review.Business?.name} disabled />
              <TextField InputLabelProps={{ shrink: true }} label="Content" {...register('content')} error={!!errors.content} helperText={errors.content?.message}/>
              <Controller
                name="visible"
                control={control}
                render={({ field }) => (
                  <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} />} label="Visible" />
                )}
              />
              {values.media && <Media url={props.review.Media?.url} />}
              {values.media && <Controller
                name="media"
                control={control}
                render={({ field }) => (
                  <FormControlLabel control={<Checkbox checked={!!field.value} onChange={field.onChange} />} label="Keep media" />
                )}
              />}
            </Stack>
          </div>
          <div style={{ marginTop: '1em' }}>
            <Button size="large" sx={{ marginRight: 3 }} type="submit" variant="contained" color="success">
              Update
            </Button>

            <Button size="large" type="button" color="error" onClick={() => props.handleClose()}>
              Cancel
            </Button>
          </div>
        </form>
      </Grid>
    </Container>
  );
};

interface PropsInterface {
  review: any;
  handleClose: (isSuccess?: number) => void;
}