import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import { Item } from "../../styles/common";
import { columnTypes, Table } from "../../common/table/Table";
import React, { useCallback } from "react";
import { columns } from "./review";
import { useQuery } from "@apollo/client";
import { REVIEWS } from "./reviews.queries";
import { Popover } from "../../common/popover/Popover";
import { EditReview } from "./EditReview";

const perPage = Number(process.env.REACT_APP_PAGINATION_ROWS_PER_PAGE ?? 5);

export const Reviews = () => {

  let variables = React.useRef<any>({
    "pagination": {
      "skip": 0,
      "take": perPage
    }
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(perPage);
  const [visible, setVisible] = React.useState<string>('all');
  const { data, loading, refetch } = useQuery(REVIEWS, {
    variables: variables.current,
  });

  const [reviewToEdit, setReviewToEdit] = React.useState<any>(null);
  const [isEditReviewOpen, setIsEditReviewOpen] = React.useState(false);

  const editFn = useCallback((item: any) => {
    setReviewToEdit(item);
    setIsEditReviewOpen(true);
  }, []);

  React.useEffect(() => {
    variables.current = {
      pagination: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
      visible: visible === 'all' ? undefined : visible === 'visible' ? true : false,
    };
    refetch(variables.current);
  }, [visible, page, rowsPerPage]);

  if (loading) {
    return <div>Loading...</div>
  }

  console.log(data);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Popover
        open={isEditReviewOpen}
        handleClose={() => {
          setIsEditReviewOpen(false);
        }}
      >
        <EditReview
          review={reviewToEdit}
          handleClose={(isSuccess: number | undefined) => {
            if (isSuccess) {
              refetch(variables.current);
            }
            setIsEditReviewOpen(false);
          }}
        />
      </Popover>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={{ m: 2 }} variant="h4">Reviews</Typography>
            </Grid>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Select value={visible} onChange={(e) => setVisible(e.target.value)}>
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="visible">Visible</MenuItem>
              <MenuItem value="hidden">Hidden</MenuItem>
            </Select>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Table
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              // onSort={handleSort}
              loading={false}
              columns={[
                ...columns,
                {
                  label: "",
                  key: "action",
                  textAlign: "right",
                  type: columnTypes.EDIT,
                  actionFn: editFn,
                },
              ]}
              rows={data.fetchAllReviews.reviews}
              total={data.fetchAllReviews.total}
            />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
