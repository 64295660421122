import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { zodResolver } from '@hookform/resolvers/zod';
import { Autocomplete, Box, Button, colors, Container, debounce, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Control, Controller, useForm, UseFormGetValues, UseFormSetValue, useWatch } from "react-hook-form";
import * as z from 'zod';
import { useToast } from "../../common/hooks/useToast";
import { UPDATE_USER } from "./users.mutation";
import { CITY_GOOGLE_PLACE_AUTOCOMPLETE, FETCH_CITY_GOOGLE_PLACE, USER_DETAILS } from "./users.queries";


interface PropsInterface {
  user: any;
  handleClose: (isSuccess?: number) => void;
}

const schema = z.object({
  id: z.coerce.number(),
  uid: z.string(),
  username: z.string().min(1, { message: 'Required' }),
  firstName: z.string().min(1, { message: 'Required' }),
  lastName: z.string().min(1, { message: 'Required' }),
  email: z.string().min(1, { message: 'Required' }),
  phone: z.string().nullable(),
  userLevel: z.enum(['Beginner', 'Guardian', 'Protector']),
  regionCity: z.string().nullable(),
  regionState: z.string().nullable(),
  regionStateCode: z.string().nullable(),
  regionCountry: z.string().nullable(),
  regionCountryCode: z.string().nullable(),
  gender: z.string().nullable(),
  totalPoints: z.coerce.number().nullable(),
  membershipType: z.enum(['FREE', 'PRIME', 'BUSINESS']),
  streetAddress: z.string().nullable(),
  tShirtSize: z.enum(['S', 'M', 'L', 'XL', 'XXL', 'XXXL']).nullable(),
  role: z.enum(['USER', 'ADMIN']),
  profileImage: z.any(),
});

type SchemaProps = z.infer<typeof schema>;

export const EditUser: React.FC<PropsInterface> = (props: PropsInterface) => {


  const {success, error: toastError} = useToast();

  const { data, loading } = useQuery(USER_DETAILS, { fetchPolicy: 'network-only', variables: { id: Number(props.user.id) } });

  const [ updateUser, updateResult ] = useMutation(UPDATE_USER);

  const [values, setValues] = useState<any>({});
  const [profileImage, setProfileImage] = useState<any>(null);

  useEffect(() => {
    if (data) {
      const { id, uid, username, firstName, lastName, email, phone, userLevel, regionCity, regionCountry, regionCountryCode, regionState, regionStateCode, gender, totalPoints, membershipType, streetAddress, tShirtSize, role } = data.fetchUserDetails;
      setProfileImage(data.fetchUserDetails.ProfileImage?.url);
      setValues({
        id,
        uid,
        username,
        firstName,
        lastName,
        email,
        phone,
        userLevel,
        gender,
        totalPoints,
        membershipType,
        streetAddress,
        tShirtSize,
        role,
        regionCity,
        regionCountry,
        regionCountryCode,
        regionState,
        regionStateCode,
        profileImage: null,
      });
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<SchemaProps>({
    values,
    resolver: zodResolver(schema),
  });

  console.log('errors', errors);

  const submitData = useCallback((values: any) => {
    console.log('value', values);
    const userUpdateInput = {
      username: { "set": values.username },
      firstName: { "set": values.firstName },
      lastName: { "set": values.lastName },
      email: { "set": values.email },
      phone: { "set": values.phone },
      userLevel: { "set": values.userLevel },
      gender: { "set": values.gender },
      totalPoints: { "set": values.totalPoints },
      membershipType: { "set": values.membershipType },
      streetAddress: { "set": values.streetAddress },
      tShirtSize: { "set": values.tShirtSize },
      role: { "set": values.role },
      regionCity: { "set": values.regionCity },
      regionCountry: { "set": values.regionCountry },
      regionCountryCode: { "set": values.regionCountryCode },
      regionState: { "set": values.regionState },
      regionStateCode: { "set": values.regionStateCode },

    };
    updateUser({
      variables: {
        id: Number(props.user.id),
        userUpdateInput: userUpdateInput,
        file: values.profileImage,
      }
    })
  }, [props.user.id, updateUser]);

  useEffect(() => {
    if (updateResult.error) {
        toastError('Updating the user failed. Please try again.');
    } else if (updateResult.data) {
        success('User updated successfully');
        props.handleClose(1);
    }
  }, [updateResult.error, updateResult.data, toastError, success, props]);

  if (loading) {
    return (<></>);
  }

  return (
    <Container maxWidth='lg' sx={{ width: '45rem', backgroundColor: colors.common.white, p: '2rem', border: '1px solid black' }}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 3 }} variant="h4">Edit user</Typography>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit((d) => submitData(d))}
        >
          <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
            <Stack spacing={2}>
              <TextField InputLabelProps={{ shrink: true }} label="id" {...register('id')} disabled  />
              <TextField InputLabelProps={{ shrink: true }} label="uid" {...register('uid')} disabled  />
              <TextField InputLabelProps={{ shrink: true }} label="Username" {...register('username')} error={!!errors.username} helperText={errors.username?.message}  />
              <TextField InputLabelProps={{ shrink: true }} label="First name" {...register('firstName')} error={!!errors.firstName} helperText={errors.firstName?.message}/>
              <TextField InputLabelProps={{ shrink: true }} label="Last name" {...register('lastName')} error={!!errors.lastName} helperText={errors.lastName?.message}/>
              <TextField InputLabelProps={{ shrink: true }} label="Email" {...register('email')} error={!!errors.email} helperText={errors.email?.message}/>
              <TextField InputLabelProps={{ shrink: true }} label="Phone" {...register('phone')} error={!!errors.phone} helperText={errors.phone?.message}/>
              <InputLabel id="role-label">Role</InputLabel>
              <Controller
                name="role"
                control={control}
                render={(options) => (<Select
                  labelId="role-label"
                  id="role"
                  value={options.field.value ?? null}
                  label="Role"
                  onChange={options.field.onChange}
                >
                  <MenuItem value={'USER'}>User</MenuItem>
                  <MenuItem value={'ADMIN'}>Admin</MenuItem>
                </Select>)}
              />
              <InputLabel id="userLevel-label">User level</InputLabel>
              <Controller
                name="userLevel"
                control={control}
                render={(options) => (<Select
                  labelId="userLevel-label"
                  id="userLevel"
                  value={options.field.value ?? 'Beginner'}
                  label="User Level"
                  onChange={options.field.onChange}
                >
                  <MenuItem value={'Beginner'}>Beginner</MenuItem>
                  <MenuItem value={'Guardian'}>Guardian</MenuItem>
                  <MenuItem value={'Protector'}>Protector</MenuItem>
                </Select>)}
              />
              <CityAutocomplete control={control} setValue={setValue} getValues={getValues} />
              <TextField InputLabelProps={{ shrink: true }} label="Street address" {...register('streetAddress')} error={!!errors.streetAddress} helperText={errors.streetAddress?.message}/>
              <InputLabel id="gender-label">Gender</InputLabel>
              <Controller
                name="gender"
                control={control}
                render={(options) => (<Select
                  labelId="gender-label"
                  id="gender"
                  value={options.field.value ?? null}
                  label="Gender"
                  onChange={options.field.onChange}
                >
                  <MenuItem value={'Male'}>Male</MenuItem>
                  <MenuItem value={'Female'}>Female</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>)}
              />
              <TextField InputLabelProps={{ shrink: true }} label="Total points" {...register('totalPoints')} type="number" error={!!errors.totalPoints} helperText={errors.totalPoints?.message}/>
              <InputLabel id="membershipType-label">Membership type</InputLabel>
              <Controller
                name="membershipType"
                control={control}
                render={(options) => (<Select
                  labelId="membershipType-label"
                  id="membershipType"
                  value={options.field.value ?? null}
                  label="Membership type"
                  onChange={options.field.onChange}
                >
                  <MenuItem value={'FREE'}>Free</MenuItem>
                  <MenuItem value={'PRIME'}>Prime</MenuItem>
                  <MenuItem value={'BUSINESS'}>Business</MenuItem>
                </Select>)}
              />
              <InputLabel id="tShirtSize-label">T-shirt size</InputLabel>
              <Controller
                name="tShirtSize"
                control={control}
                render={(options) => (<Select
                  labelId="tShirtSize-label"
                  id="tShirtSize"
                  value={options.field.value ?? null}
                  label="T-shirt size"
                  onChange={options.field.onChange}
                >
                  <MenuItem value={'S'}>S</MenuItem>
                  <MenuItem value={'M'}>M</MenuItem>
                  <MenuItem value={'L'}>L</MenuItem>
                  <MenuItem value={'XL'}>XL</MenuItem>
                  <MenuItem value={'XXL'}>XXL</MenuItem>
                  <MenuItem value={'XXXL'}>XXXL</MenuItem>
                </Select>)}
              />
              {profileImage && (
                <div style={{ maxHeight: '100px', textAlign: 'center' }}>
                  <img alt="profile" src={profileImage} height={100} />
                </div>
              )}
              <Controller
                name="profileImage"
                control={control}
                render={({ field, fieldState }) => (
                  <MuiFileInput
                    {...field}
                    placeholder="Browse new profile image"
                    helperText={fieldState.invalid ? "File is invalid" : ""}
                    error={fieldState.invalid}
                  />
                )}
              />
              <div style={{ height: '20px' }}></div>
            </Stack>
          </div>


          <div style={{marginTop: '1em'}}>
            <Button size="large" sx={{ marginRight: 3 }} type="submit" variant="contained" color="success">
                Update
            </Button>

            <Button size="large" type="button" color="error" onClick={() => props.handleClose()}>
                Cancel
            </Button>
          </div>
        </form>
      </Grid>
    </Container>
  );
};

interface PlaceType {
  placeId: string;
  description: string;
}

interface CityAutocompleteProps {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
}

const CityAutocomplete: React.FC<CityAutocompleteProps> = (props: CityAutocompleteProps) : JSX.Element => {
  const [value, setValue] = useState<PlaceType | null>(null)
  const [inputValue, setInputValue] = useState('');
  const [getOptions, { data, loading }] = useLazyQuery(CITY_GOOGLE_PLACE_AUTOCOMPLETE);
  const [getCityDetails, { data: cityDetailsData, }] = useLazyQuery(FETCH_CITY_GOOGLE_PLACE);
  const [options, setOptions] = useState<PlaceType[]>([]);

  const fetch = useMemo(() => debounce((filter: string) => {
    getOptions({
      variables: {
        filter: !!filter ? filter : null,
      }
    });
  }, 400), [getOptions]);

  useWatch({
    control: props.control,
    name: 'regionCity'
  });

  const regionCity = props.getValues('regionCity');
  const regionState = props.getValues('regionState');
  const regionCountry = props.getValues('regionCountry');

  useEffect(() => {
    if (regionCity) {
      setValue({
        placeId: '',
        description: `${regionCity}, ${regionState}, ${regionCountry}`,
      });
      setInputValue(`${regionCity}, ${regionState}, ${regionCountry}`);
    }
  }, [regionCity, regionCountry, regionState])

  useEffect(() => {
    fetch(inputValue);
  }, [fetch, inputValue]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (data) {
      console.log('loaded', data);
      setOptions(data.getCitiesGooglePlaceAutocomplete);
    }
  }, [data, loading,]);

  useEffect(() => {
    if (value?.placeId) {
      getCityDetails({
        variables: {
          placeId: value.placeId,
        }
      });
    }
  }, [getCityDetails, value]);

  useEffect(() => {
    if (cityDetailsData) {
      props.setValue('regionCity', cityDetailsData.fetchCityGooglePlace.regionCity);
      props.setValue('regionState', cityDetailsData.fetchCityGooglePlace.regionState);
      props.setValue('regionStateCode', cityDetailsData.fetchCityGooglePlace.regionStateCode);
      props.setValue('regionCountry', cityDetailsData.fetchCityGooglePlace.regionCountry);
      props.setValue('regionCountryCode', cityDetailsData.fetchCityGooglePlace.regionCountryCode);
    }
  }, [cityDetailsData, props]);

  return (
    <Autocomplete
        options={options}
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        noOptionsText={'Search city'}
        value={value}
        onInputChange={(event, newInputValue) => {
          console.log(newInputValue);
          setInputValue(newInputValue);
        }}
        onChange={(event: any, newValue) => {
          if (newValue) {
            setValue(newValue);
          }
        }}
        getOptionLabel={(option: any) => option.description}
        renderInput={(params) => (
          <TextField {...params} label="City" fullWidth />
        )}
        renderOption={(props: any, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    <Box>
                      {option.description}
                    </Box>

                </Grid>
              </Grid>
            </li>
          );
        }}
      />
  );
}