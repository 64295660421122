import { DocumentNode, useLazyQuery, useQuery } from "@apollo/client";
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Box, Button, debounce, Grid, IconButton, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FilterItem } from "../users/UserFilters";
import { BUSINESS_CITY_FILTER_AUTOCOMPLETE, BUSINESS_COUNTRY_FILTER_AUTOCOMPLETE, BUSINESS_FIELDS, BUSINESS_STATE_FILTER_AUTOCOMPLETE } from "./businesses.queries";

export interface BusinessFilter {
  filters: Array<{
    field: string;
    operator: string;
    value: string
  }>;
  operator: 'OR'|'AND';
}

interface BusinessFilterProps {
  onFilter: (filter: BusinessFilter) => void;
}

export const BusinessFilters: React.FC<BusinessFilterProps> = (props: BusinessFilterProps) : JSX.Element => {
  const { data, loading, error } = useQuery(BUSINESS_FIELDS);
  const [filterItems, setFilterItems] = useState<Array<FilterItem>>([]);
  const [operator, setOperator] = useState<'AND' | 'OR'>('AND');

  const handleFilter = useCallback((event: any) => {
    const nonEmptyFilters = filterItems.filter((filter) => filter.field !== '');
    setFilterItems([
      ...nonEmptyFilters
    ]);
    props.onFilter({
      filters: nonEmptyFilters.map((item) => ({
        field: item.field,
        operator: item.operator,
        value: `${(typeof item.value === 'object' && !(item.value instanceof Date)) ? item.value.name : item.value}`
      })),
      operator,
    });
  }, [filterItems, operator, props]);

  const addEmptyCondition = useCallback((event: any) => {
    event.preventDefault();
    setFilterItems([
      ...filterItems,
      { field: '', operator: 'equals', value: '', type: '', kind: '' },
    ])
  }, [filterItems]);

  const updateFilterItem = useCallback((item: FilterItem, index: number) => {
    filterItems[index] = item;
    setFilterItems([
      ...filterItems,
    ]);
  }, [filterItems]);

  const deleteFilterItem = useCallback((index: number) => {
    setFilterItems(filterItems.filter((value, i) => i !== index));
  }, [filterItems]);

  const fields = useMemo(() => {
    if (loading || error) {
      return [];
    }
    const businessFields = data.fetchBusinessFields.fields.filter((field: any) => !field.isReference);

    return [
      ...businessFields,
      {
        name: 'country',
        type: 'String',
        kind: 'scalar',
        isReference: true,
      },
      {
        name: 'state',
        type: 'String',
        kind: 'scalar',
        isReference: true,
      },
      {
        name: 'city',
        type: 'String',
        kind: 'scalar',
        isReference: true,
      },
    ];
  }, [data, error, loading]);

  if (loading) {
    return (<></>);
  }
  if (error) {
    return (<div>{error.message}</div>);
  }

  return (
    <div>
      <Button  onClick={addEmptyCondition}>Add new condition</Button>
      {filterItems.map((item, index) => (
        <div key={`item-${index}-${item.field}`}>
          <Grid container>
            <Grid item xs={2}>
              <Select
                value={item.field}
                onChange={(event: any) => {
                  updateFilterItem({
                    ...item,
                    field: event.target.value,
                    type: fields.find((field: any) => field.name === event.target.value).type,
                    kind: fields.find((field: any) => field.name === event.target.value).kind,
                  }, index);
                }}
              >
                {fields.map((field: any) => (<MenuItem key={field.name} value={field.name}>{field.name}</MenuItem>))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Select
                value={item.operator}
                onChange={(event: any) => {
                  updateFilterItem({
                    ...item,
                    operator: event.target.value,
                  }, index);
                }}
              >
                <MenuItem value={'equals'}>equals</MenuItem>
                {(item.type === 'String' && !['city', 'state', 'country'].includes(item.field)) && (<MenuItem value={'contains'}>contains</MenuItem>)}
                {(item.type === 'String' && !['city', 'state', 'country'].includes(item.field)) && (<MenuItem value={'startsWith'}>startsWith</MenuItem>)}
                {item.type === 'DateTime' && (<MenuItem value={'lte'}>less than equal</MenuItem>)}
                {item.type === 'DateTime' && (<MenuItem value={'gte'}>greater than equal</MenuItem>)}
              </Select>
            </Grid>
            <Grid item xs={7}>
              {item.type === 'Boolean' && (
                <Select
                  value={item.value === true}
                  onChange={(event: any) => {
                    updateFilterItem({
                      ...item,
                      value: event.target.value === 'true',
                    }, index);
                  }}
                >
                  <MenuItem value={'true'}>true</MenuItem>
                  <MenuItem value={'false'}>false</MenuItem>
                </Select>
              )}
              {((item.type === 'String' && !['city', 'state', 'country'].includes(item.field)) || item.kind === 'enum') && (
                <TextField
                  value={item.value}
                  onChange={(event: any) => {
                    updateFilterItem({
                      ...item,
                      value: event.target.value,
                    }, index);
                  }}
                />
              )}
              {(item.type === 'Int') && (
                <TextField
                  type="number"
                  value={item.value}
                  onChange={(event: any) => {
                    updateFilterItem({
                      ...item,
                      value: event.target.value,
                    }, index);
                  }}
              />
              )}
              {item.type === 'DateTime' && (
                <DatePicker onChange={(value) => {
                  updateFilterItem({
                    ...item,
                    value: value?.toDate() ?? new Date(),
                  }, index);
                }} />
              )}
              {item.field === 'country' && (
                <FilterAutocomplete
                  query={BUSINESS_COUNTRY_FILTER_AUTOCOMPLETE}
                  label="Country"
                  noOptionsText="No country"
                  value={item.value === '' ? {displayName: '', name: ''} : item.value as { displayName: string, name: string }}
                  optionExtractor={(data) => data.getBusinessCountriesAutocomplete.map((country: any) => ({
                    displayName: country.country,
                    name: country.country,
                  }))}
                  onChange={(value) => {
                    updateFilterItem({
                      ...item,
                      value: value,
                    }, index);
                  }}
                />
              )}
              {item.field === 'state' && (
                <FilterAutocomplete
                  query={BUSINESS_STATE_FILTER_AUTOCOMPLETE}
                  label="State"
                  noOptionsText="No state"
                  value={item.value === '' ? {displayName: '', name: ''} : item.value as { displayName: string, name: string }}
                  optionExtractor={(data) => data.getBusinessStatesAutocomplete.map((state: any) => ({
                    name: state.state,
                    displayName: `${state.state}, ${state.country}`,
                  }))}
                  onChange={(value) => {
                    updateFilterItem({
                      ...item,
                      value: value,
                    }, index);
                  }}
                />
              )}
              {item.field === 'city' && (
                <FilterAutocomplete
                  query={BUSINESS_CITY_FILTER_AUTOCOMPLETE}
                  label="City"
                  noOptionsText="No city"
                  value={item.value === '' ? {displayName: '', name: ''} : item.value as { displayName: string, name: string }}
                  optionExtractor={(data) => data.getBusinessCitiesAutocomplete.map((city: any) => ({
                    name: city.city,
                    displayName: `${city.city}, ${city.state}, ${city.country}`,
                  }))}
                  onChange={(value) => {
                    updateFilterItem({
                      ...item,
                      value: value,
                    }, index);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="delete" onClick={() => deleteFilterItem(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      ))}
      <div>
        Operator among conditions:
        <Select
        value={operator}
        onChange={(event: any) => setOperator(event.target.value)}
      >
        <MenuItem value={'AND'}>AND</MenuItem>
        <MenuItem value={'OR'}>OR</MenuItem>
      </Select>
      </div>
      <div>
        <Button variant="contained" onClick={handleFilter}>Filter</Button>
      </div>
    </div>
  );
};


export interface FilterAutocompleteProps {
  value: { displayName: string, name: string }
  onChange: (value: { displayName: string, name: string }) => void,
  query: DocumentNode,
  noOptionsText: string,
  label: string,
  optionExtractor: (data: any) => Array<any>;
}

export const FilterAutocomplete: React.FC<FilterAutocompleteProps> = (props: FilterAutocompleteProps) : JSX.Element => {
  const [inputValue, setInputValue] = useState('');
  const [getOptions, { data, loading }] = useLazyQuery(props.query);
  const [options, setOptions] = useState<any>([]);

  const fetch = useMemo(() => debounce((filter: string) => {
    getOptions({
      variables: {
        filter: !!filter ? filter : null,
      }
    });
  }, 400), [getOptions]);

  useEffect(() => {
    fetch(inputValue);
  }, [fetch, inputValue]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (data) {
      setOptions(props.optionExtractor(data));
    }
  }, [data, loading, props, props.optionExtractor]);

  return (
    <Autocomplete
        options={options}
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        noOptionsText={props.noOptionsText}
        value={props.value}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event: any, newValue) => {
            props.onChange(newValue ?? { displayName: '', name: '' });
        }}
        getOptionLabel={(option: any) => option.displayName}
        renderInput={(params) => (
          <TextField {...params} label={props.label} fullWidth />
        )}
        renderOption={(props: any, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    <Box>
                      {option.displayName}
                    </Box>

                </Grid>
              </Grid>
            </li>
          );
        }}
      />
  );
}