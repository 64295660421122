import { gql } from "@apollo/client";

export const REVIEWS = gql`
  query fetchAllReviews($pagination: PaginationInputType, $visible: Boolean) {
    fetchAllReviews(pagination: $pagination, visible: $visible) {
      reviews {
        id
        content
        createdAt
        visible
        User {
          username
        }
        Business {
          name 
        }
        Media {
          url
        }
      }
      total
    }
  }
`;