import mime from 'mime';
export const Media = ({ url }: { url: string }) => {
  const mimeType = mime.getType(url);
  if (mimeType?.startsWith('image')) {
    return <img src={url} alt="media" width={150} />
  }
  if (mimeType?.startsWith('video')) {
    return <video src={url} controls width={150}/>
  }
  return <a href={url}>{mimeType}</a>
}