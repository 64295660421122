import { useQuery } from "@apollo/client";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { DayPicker } from "react-day-picker";
import { Item } from "../../styles/common";
import { BUSINESS_CITY_FILTER_AUTOCOMPLETE, BUSINESS_COUNTRY_FILTER_AUTOCOMPLETE, BUSINESS_STATE_FILTER_AUTOCOMPLETE } from "../businesses/businesses.queries";
import { FilterAutocomplete } from "../businesses/BusinessFilters";
import { FilterAutocomplete as UserFilterAutocomplete } from "../users/UserFilters";
import { CITY_FILTER_AUTOCOMPLETE, COUNTRY_FILTER_AUTOCOMPLETE, STATE_FILTER_AUTOCOMPLETE } from "../users/users.queries";
import { JOINED_TEAMS_COUNT, RECOMMENDED_BUSINESSES_COUNT, RECOMMENDED_UNIQUE_BUSINESSES_COUNT, RECOMMENDER_COUNT, REFERRED_USER_COUNT } from "./reports.queries";

export const Reports: React.FC<any> = (): JSX.Element => {
  const [regionCountry, setRegionCountry] = useState<any>(null);
  const [regionState, setRegionState] = useState<any>(null);
  const [regionCity, setRegionCity] = useState<any>(null);

  const [userRegionCountry, setUserRegionCountry] = useState<any>(null);
  const [userRegionState, setUserRegionState] = useState<any>(null);
  const [userRegionCity, setUserRegionCity] = useState<any>(null);

  const [dateRange, setDateRange] = useState<any>(null);
  const [showDateRangePicker, setShowDateRangePicker] = useState<boolean>(false);

  const { data, loading} = useQuery(REFERRED_USER_COUNT, {
    variables: {
      dateFrom: dateRange?.from,
      dateTo: dateRange ? new Date(new Date(dateRange.to).setHours(23,59,59,999)) : null,
    }
  });
  const { data: businessData, loading: businessLoading, refetch: refetchBusiness } = useQuery(RECOMMENDED_BUSINESSES_COUNT, {
    variables: {
      dateFrom: dateRange?.from,
      dateTo: dateRange ? new Date(new Date(dateRange.to).setHours(23,59,59,999)) : null,
      regionCountry: regionCountry ? regionCountry.name : null,
      regionState: regionState ? regionState.name : null,
      regionCity: regionCity ? regionCity.name : null,
    }
  });
  const { data: uniqueBusinessData, loading: uniqueBusinessLoading, refetch: refetchUniqueBusiness } = useQuery(RECOMMENDED_UNIQUE_BUSINESSES_COUNT, {
    variables: {
      dateFrom: dateRange?.from,
      dateTo: dateRange ? new Date(new Date(dateRange.to).setHours(23,59,59,999)) : null,
      regionCountry: regionCountry ? regionCountry.name : null,
      regionState: regionState ? regionState.name : null,
      regionCity: regionCity ? regionCity.name : null,
    }
  });
  const { data: recommenderData, loading: recommenderLoading, refetch: refetchRecommender } = useQuery(RECOMMENDER_COUNT, {
    variables: {
      dateFrom: dateRange?.from,
      dateTo: dateRange ? new Date(new Date(dateRange.to).setHours(23,59,59,999)) : null,
      regionCountry: userRegionCountry ? userRegionCountry.name : null,
      regionState: userRegionState ? userRegionState.name : null,
      regionCity: userRegionCity ? userRegionCity.name : null,
    }
  });
  const { data: joinedTeamsData, loading: joinedTeamsLoading, refetch: refetchJoined } = useQuery(JOINED_TEAMS_COUNT, {
    variables: {
      dateFrom: dateRange?.from,
      dateTo: dateRange ? new Date(new Date(dateRange.to).setHours(23,59,59,999)) : null,
      regionCountry: userRegionCountry ? userRegionCountry.name : null,
      regionState: userRegionState ? userRegionState.name : null,
      regionCity: userRegionCity ? userRegionCity.name : null,
    }
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Typography sx={{ m: 2 }} variant="h4">Reports</Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Item>
                <h3>
                  Date range:
                  <span style={{width: '1rem', display: 'inline-block'}}></span>
                  <Button variant="contained" onClick={() => {setShowDateRangePicker(!showDateRangePicker)}}>
                    {dateRange && (<span>{dateRange.from.toLocaleDateString()}</span>)}
                    {dateRange?.to && (<span> - {dateRange.to.toLocaleDateString()}</span>)}
                    {!dateRange && (<>Tap to set a date range filter</>)}
                  </Button>
                  <Button color="error" onClick={() => {setDateRange(null)}}>
                    x
                  </Button>
                </h3>
                {showDateRangePicker && (<DayPicker mode="range" selected={dateRange} onSelect={setDateRange} />)}
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Item>
                <h1>People</h1>
              </Item>
              <div style={{height: '1rem'}}></div>
              <Item>
                <UserFilterAutocomplete
                  query={COUNTRY_FILTER_AUTOCOMPLETE}
                  label="Country"
                  noOptionsText="No country"
                  value={userRegionCountry}
                  optionExtractor={(data) => data.getCountriesAutocomplete.map((country: any) => ({
                    displayName: country.regionCountry,
                    name: country.regionCountry,
                  }))}
                  onChange={(value) => {
                    setUserRegionCountry(value);
                    refetchJoined();
                    refetchRecommender();
                  }}
                />
                <UserFilterAutocomplete
                  query={STATE_FILTER_AUTOCOMPLETE}
                  label="State"
                  noOptionsText="No state"
                  value={userRegionState}
                  optionExtractor={(data) => data.getStatesAutocomplete.map((state: any) => ({
                    name: state.regionState,
                    displayName: `${state.regionState}, ${state.regionCountry}`,
                  }))}
                  onChange={(value) => {
                    setUserRegionState(value);
                    refetchJoined();
                    refetchRecommender();
                  }}
                />
                <UserFilterAutocomplete
                  query={CITY_FILTER_AUTOCOMPLETE}
                  label="City"
                  noOptionsText="No city"
                  value={userRegionCity}
                  optionExtractor={(data) => data.getCitiesAutocomplete.map((city: any) => ({
                    name: city.regionCity,
                    displayName: `${city.regionCity}, ${city.regionState}, ${city.regionCountry}`,
                  }))}
                  onChange={(value) => {
                    setUserRegionCity(value);
                    refetchJoined();
                    refetchRecommender();
                  }}
                />
              </Item>
              <div style={{height: '1rem'}}></div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Item>
                <h3>Number of people referred</h3>
                {loading && (<CircularProgress />)}
                {!loading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{data.reportReferredUsers}</div>)}
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Item>
                <h3>Number of recommenders (Total)</h3>
                {recommenderLoading && (<CircularProgress />)}
                {!recommenderLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{recommenderData.reportRecommenders.total}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of recommenders (US)</h3>
                {recommenderLoading && (<CircularProgress />)}
                {!recommenderLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{recommenderData.reportRecommenders.us}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of recommenders (DMV)</h3>
                {recommenderLoading && (<CircularProgress />)}
                {!recommenderLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{recommenderData.reportRecommenders.dmv}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of recommenders (Zimbabwe)</h3>
                {recommenderLoading && (<CircularProgress />)}
                {!recommenderLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{recommenderData.reportRecommenders.zimbabwe}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of recommenders (Filter)</h3>
                {recommenderLoading && (<CircularProgress />)}
                {!recommenderLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{recommenderData.reportRecommenders.filter}</div>)}
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Item>
                <h3>Number of users who joined a team (Total)</h3>
                {joinedTeamsLoading && (<CircularProgress />)}
                {!joinedTeamsLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{joinedTeamsData.reportJoinedTeams.total}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of users who joined a team (US)</h3>
                {joinedTeamsLoading && (<CircularProgress />)}
                {!joinedTeamsLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{joinedTeamsData.reportJoinedTeams.us}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of users who joined a team (DMV)</h3>
                {joinedTeamsLoading && (<CircularProgress />)}
                {!joinedTeamsLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{joinedTeamsData.reportJoinedTeams.dmv}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of users who joined a team (Zimbabwe)</h3>
                {joinedTeamsLoading && (<CircularProgress />)}
                {!joinedTeamsLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{joinedTeamsData.reportJoinedTeams.zimbabwe}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of users who joined a team (Filter)</h3>
                {joinedTeamsLoading && (<CircularProgress />)}
                {!joinedTeamsLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{joinedTeamsData.reportJoinedTeams.filter}</div>)}
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
          <Grid item xs={12}>
              <Item>
                <h1>Businesses</h1>
              </Item>
              <div style={{height: '1rem'}}></div>
              <Item>
                <h3>Country</h3>
                <FilterAutocomplete
                  query={BUSINESS_COUNTRY_FILTER_AUTOCOMPLETE}
                  label="Country"
                  noOptionsText="No country"
                  value={regionCountry}
                  optionExtractor={(data) => data.getBusinessCountriesAutocomplete.map((country: any) => ({
                    displayName: country.country,
                    name: country.country,
                  }))}
                  onChange={(value) => {
                    setRegionCountry(value);
                    refetchBusiness();
                    refetchUniqueBusiness();
                  }}
                />
                <h3>State</h3>
                <FilterAutocomplete
                  query={BUSINESS_STATE_FILTER_AUTOCOMPLETE}
                  label="State"
                  noOptionsText="No state"
                  value={regionState}
                  optionExtractor={(data) => data.getBusinessStatesAutocomplete.map((state: any) => ({
                    name: state.state,
                    displayName: `${state.state}, ${state.country}`,
                  }))}
                  onChange={(value) => {
                    setRegionState(value);
                    refetchBusiness();
                    refetchUniqueBusiness();
                  }}
                />
                <h3>City</h3>
                <FilterAutocomplete
                  query={BUSINESS_CITY_FILTER_AUTOCOMPLETE}
                  label="City"
                  noOptionsText="No city"
                  value={regionCity}
                  optionExtractor={(data) => data.getBusinessCitiesAutocomplete.map((city: any) => ({
                    name: city.city,
                    displayName: `${city.city}, ${city.state}, ${city.country}`,
                  }))}
                  onChange={(value) => {
                    setRegionCity(value);
                    refetchBusiness();
                    refetchUniqueBusiness();
                  }}
                />
              </Item>
              <div style={{height: '1rem'}}></div>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Item>
                <h3>Number of Business Recommendations (Total)</h3>
                {businessLoading && (<CircularProgress />)}
                {!businessLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{businessData.reportBusinessRecommendations.total}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of Business Recommendations (US)</h3>
                {businessLoading && (<CircularProgress />)}
                {!businessLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{businessData.reportBusinessRecommendations.us}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of Business Recommendations (DMV)</h3>
                {businessLoading && (<CircularProgress />)}
                {!businessLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{businessData.reportBusinessRecommendations.dmv}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of Business Recommendations (Zimbabwe)</h3>
                {businessLoading && (<CircularProgress />)}
                {!businessLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{businessData.reportBusinessRecommendations.zimbabwe}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of Business Recommendations (Filter)</h3>
                {businessLoading && (<CircularProgress />)}
                {!businessLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{businessData.reportBusinessRecommendations.filter}</div>)}
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Item>
                <h3>Number of Unique Business Recommended (Total)</h3>
                {uniqueBusinessLoading && (<CircularProgress />)}
                {!uniqueBusinessLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{uniqueBusinessData.reportUniqueBusinessRecommendations.total}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of Unique Business Recommended (US)</h3>
                {uniqueBusinessLoading && (<CircularProgress />)}
                {!uniqueBusinessLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{uniqueBusinessData.reportUniqueBusinessRecommendations.us}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of Unique Business Recommended (DMV)</h3>
                {uniqueBusinessLoading && (<CircularProgress />)}
                {!uniqueBusinessLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{uniqueBusinessData.reportUniqueBusinessRecommendations.dmv}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of Unique Business Recommended (Zimbabwe)</h3>
                {uniqueBusinessLoading && (<CircularProgress />)}
                {!uniqueBusinessLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{uniqueBusinessData.reportUniqueBusinessRecommendations.zimbabwe}</div>)}
              </Item>
            </Grid>
            <Grid item xs={3}>
              <Item>
                <h3>Number of Unique Business Recommended (Filter)</h3>
                {uniqueBusinessLoading && (<CircularProgress />)}
                {!uniqueBusinessLoading && (<div style={{textAlign: 'center', fontSize: '3rem', fontWeight: 'bold'}}>{uniqueBusinessData.reportUniqueBusinessRecommendations.filter}</div>)}
              </Item>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}