import { gql } from '@apollo/client';

export const REFERRED_USER_COUNT = gql`
  query reportReferredUsers($dateFrom: DateTime, $dateTo: DateTime) {
    reportReferredUsers(dateFrom: $dateFrom, dateTo: $dateTo)
  }
`;

export const RECOMMENDER_COUNT = gql`
  query reportRecommenders(
    $dateFrom: DateTime
    $dateTo: DateTime
    $regionCountry: String
    $regionState: String
    $regionCity: String
  ) {
    reportRecommenders(
      dateFrom: $dateFrom
      dateTo: $dateTo
      regionCountry: $regionCountry
      regionState: $regionState
      regionCity: $regionCity
    ) {
      total
      us
      zimbabwe
      dmv
      filter
    }
  }
`;

export const RECOMMENDED_BUSINESSES_COUNT = gql`
  query reportBusinessRecommendations(
    $dateFrom: DateTime
    $dateTo: DateTime
    $regionCountry: String
    $regionState: String
    $regionCity: String
  ) {
    reportBusinessRecommendations(
      dateFrom: $dateFrom
      dateTo: $dateTo
      regionCountry: $regionCountry
      regionState: $regionState
      regionCity: $regionCity
    ) {
      total
      us
      zimbabwe
      dmv
      filter
    }
  }
`;

export const RECOMMENDED_UNIQUE_BUSINESSES_COUNT = gql`
  query reportUniqueBusinessRecommendations(
    $dateFrom: DateTime
    $dateTo: DateTime
    $regionCountry: String
    $regionState: String
    $regionCity: String
  ) {
    reportUniqueBusinessRecommendations(
      dateFrom: $dateFrom
      dateTo: $dateTo
      regionCountry: $regionCountry
      regionState: $regionState
      regionCity: $regionCity
    ) {
      total
      us
      zimbabwe
      dmv
      filter
    }
  }
`;
export const JOINED_TEAMS_COUNT = gql`
  query reportJoinedTeams(
    $dateFrom: DateTime
    $dateTo: DateTime
    $regionCountry: String
    $regionState: String
    $regionCity: String
  ) {
    reportJoinedTeams(
      dateFrom: $dateFrom
      dateTo: $dateTo
      regionCountry: $regionCountry
      regionState: $regionState
      regionCity: $regionCity
    ) {
      total
      us
      zimbabwe
      dmv
      filter
    }
  }
`;
