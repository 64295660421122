
import { ColumnInterface } from "../../common/table/Table";
import { Media } from "./Media";

export const columns: Array<ColumnInterface> = [
  { label: 'Business', key: 'business', rowRender: (row) => `${row.Business?.name ?? ''}`  },
  { label: 'Username', key: 'username', rowRender: (row) => `${row.User?.username ?? ''}` },
  { label: 'Content', key: 'content', },
  { label: 'Media', key: 'media', rowRender: (row) => row.Media?.url ? (<Media url={row.Media?.url} />) : '' },
  { label: 'Created at', key: 'createdAt', rowRender: (row) => new Date(row.createdAt).toLocaleString() },
  { label: 'Visible', key: 'visible', rowRender: (row) => `${row.visible ? 'Yes' : 'No'}` },
];