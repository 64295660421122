import { DocumentNode, useLazyQuery, useQuery } from "@apollo/client";
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Box, Button, Checkbox, debounce, FormControlLabel, Grid, IconButton, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CITY_FILTER_AUTOCOMPLETE, COUNTRY_FILTER_AUTOCOMPLETE, STATE_FILTER_AUTOCOMPLETE, USER_FIELDS } from "./users.queries";

export interface FilterItem {
  field: string;
  type: string;
  kind: string;
  operator: string;
  value: string | boolean | number | Date | { displayName: string, name: string };
}

export interface Filter {
  filters: Array<{
    field: string;
    operator: string;
    value: string
  }>;
  operator: 'OR'|'AND';
  usersThatHaveReferred: boolean;
  usersThatHaveRecommended: boolean;
  usersThatHaveRecommendedSameBusiness: boolean;
  visitedPage: string;
  hasSharedReferralLink: boolean;
}

interface UserFilterProps {
  onFilter: (filter: Filter) => void;
}

export const UserFilters: React.FC<UserFilterProps> = (props: UserFilterProps) : JSX.Element => {
  const { data, loading, error } = useQuery(USER_FIELDS);
  const [filterItems, setFilterItems] = useState<Array<FilterItem>>([]);
  const [usersThatHaveReferred, setUserThatHaveReferred] = useState(false);
  const [usersThatHaveRecommended, setUserThatHaveRecommended] = useState(false);
  const [usersThatHaveRecommendedSameBusiness, setUserThatHaveRecommendedSameBusiness] = useState(false);
  const [hasSharedReferralLink, setHasSharedReferralLink] = useState(false);
  const [visitedPage, setVisitedPage] = useState('');
  const [operator, setOperator] = useState<'AND' | 'OR'>('AND');

  const handleFilter = useCallback((event: any) => {
    const nonEmptyFilters = filterItems.filter((filter) => filter.field !== '');
    setFilterItems([
      ...nonEmptyFilters
    ]);
    props.onFilter({
      filters: nonEmptyFilters.map((item) => ({
        field: item.field,
        operator: item.operator,
        value: `${(typeof item.value === 'object' && !(item.value instanceof Date)) ? item.value.name : item.value}`
      })),
      operator,
      usersThatHaveReferred,
      usersThatHaveRecommended,
      usersThatHaveRecommendedSameBusiness,
      visitedPage,
      hasSharedReferralLink,
    });
  }, [filterItems, hasSharedReferralLink, operator, props, usersThatHaveRecommended,
      usersThatHaveRecommendedSameBusiness, usersThatHaveReferred, visitedPage
    ]);

  const addEmptyCondition = useCallback((event: any) => {
    event.preventDefault();
    setFilterItems([
      ...filterItems,
      { field: '', operator: 'equals', value: '', type: '', kind: '' },
    ])
  }, [filterItems]);

  const updateFilterItem = useCallback((item: FilterItem, index: number) => {
    filterItems[index] = item;
    console.log('items', filterItems);
    setFilterItems([
      ...filterItems,
    ]);
  }, [filterItems]);

  const deleteFilterItem = useCallback((index: number) => {
    setFilterItems(filterItems.filter((value, i) => i !== index));
  }, [filterItems]);

  const fields = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return data.fetchUserFields.fields.filter((field: any) => !field.isReference && !['cityId', 'stateId','countryId'].includes(field.name));
  }, [data, error, loading]);

  if (loading) {
    return (<></>);
  }
  if (error) {
    return (<div>{error.message}</div>);
  }

  return (
    <div>
      <Button  onClick={addEmptyCondition}>Add new condition</Button>
      {filterItems.map((item, index) => (
        <div key={`item-${index}-${item.field}`}>
          <Grid container>
            <Grid item xs={2}>
              <Select
                value={item.field}
                onChange={(event: any) => {
                  updateFilterItem({
                    ...item,
                    field: event.target.value,
                    type: fields.find((field: any) => field.name === event.target.value).type,
                    kind: fields.find((field: any) => field.name === event.target.value).kind,
                  }, index);
                }}
              >
                {fields.filter((field: any) => !field.isReference).map((field: any) => (<MenuItem key={field.name} value={field.name}>{field.name}</MenuItem>))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Select
                value={item.operator}
                onChange={(event: any) => {
                  updateFilterItem({
                    ...item,
                    operator: event.target.value,
                  }, index);
                }}
              >
                <MenuItem value={'equals'}>equals</MenuItem>
                {(item.type === 'String' && !['regionCity', 'regionState', 'regionCountry'].includes(item.field)) && (<MenuItem value={'contains'}>contains</MenuItem>)}
                {(item.type === 'String' && !['regionCity', 'regionState', 'regionCountry'].includes(item.field)) && (<MenuItem value={'startsWith'}>startsWith</MenuItem>)}
                {item.type === 'DateTime' && (<MenuItem value={'lte'}>less than equal</MenuItem>)}
                {item.type === 'DateTime' && (<MenuItem value={'gte'}>greater than equal</MenuItem>)}
              </Select>
            </Grid>
            <Grid item xs={7}>
              {item.type === 'Boolean' && (
                <Select
                  value={item.value === true}
                  onChange={(event: any) => {
                    updateFilterItem({
                      ...item,
                      value: event.target.value === 'true',
                    }, index);
                  }}
                >
                  <MenuItem value={'true'}>true</MenuItem>
                  <MenuItem value={'false'}>false</MenuItem>
                </Select>
              )}
              {((item.type === 'String' && !['regionCity', 'regionState', 'regionCountry'].includes(item.field)) || item.kind === 'enum') && (
                <TextField
                  value={item.value}
                  onChange={(event: any) => {
                    updateFilterItem({
                      ...item,
                      value: event.target.value,
                    }, index);
                  }}
                />
              )}
              {(item.type === 'Int') && (
                <TextField
                  type="number"
                  value={item.value}
                  onChange={(event: any) => {
                    updateFilterItem({
                      ...item,
                      value: event.target.value,
                    }, index);
                  }}
              />
              )}
              {item.type === 'DateTime' && (
                <DatePicker onChange={(value) => {
                  updateFilterItem({
                    ...item,
                    value: value?.toDate() ?? new Date(),
                  }, index);
                }} />
              )}
              {item.field === 'regionCountry' && (<
                FilterAutocomplete
                  query={COUNTRY_FILTER_AUTOCOMPLETE}
                  label="Country"
                  noOptionsText="No country"
                  value={item.value === '' ? {displayName: '', name: ''} : item.value as { displayName: string, name: string }}
                  optionExtractor={(data) => data.getCountriesAutocomplete.map((country: any) => ({
                    displayName: country.regionCountry,
                    name: country.regionCountry,
                  }))}
                  onChange={(value) => {
                    updateFilterItem({
                      ...item,
                      value: value,
                    }, index);
                  }}
                />
              )}
              {item.field === 'regionState' && (<
                FilterAutocomplete
                  query={STATE_FILTER_AUTOCOMPLETE}
                  label="State"
                  noOptionsText="No state"
                  value={item.value === '' ? {displayName: '', name: ''} : item.value as { displayName: string, name: string }}
                  optionExtractor={(data) => data.getStatesAutocomplete.map((state: any) => ({
                    name: state.regionState,
                    displayName: `${state.regionState}, ${state.regionCountry}`,
                  }))}
                  onChange={(value) => {
                    updateFilterItem({
                      ...item,
                      value: value,
                    }, index);
                  }}
                />
              )}
              {item.field === 'regionCity' && (<
                FilterAutocomplete
                  query={CITY_FILTER_AUTOCOMPLETE}
                  label="City"
                  noOptionsText="No city"
                  value={item.value === '' ? {displayName: '', name: ''} : item.value as { displayName: string, name: string }}
                  optionExtractor={(data) => data.getCitiesAutocomplete.map((city: any) => ({
                    name: city.regionCity,
                    displayName: `${city.regionCity}, ${city.regionState}, ${city.regionCountry}`,
                  }))}
                  onChange={(value) => {
                    updateFilterItem({
                      ...item,
                      value: value,
                    }, index);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="delete" onClick={() => deleteFilterItem(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      ))}
      <div>
        <FormControlLabel
          label="Users that have referred"
          control={
            <Checkbox
              checked={usersThatHaveReferred}
              onChange={(e: any) => {
                setUserThatHaveReferred(!usersThatHaveReferred);
              }}
            />
          }
        />
      </div>
      <div>
        <FormControlLabel
          label="Users that have recommended"
          control={
            <Checkbox
              checked={usersThatHaveRecommended}
              onChange={(e: any) => {
                setUserThatHaveRecommended(!usersThatHaveRecommended);
              }}
            />
          }
        />
      </div>
      <div>
        <FormControlLabel
          label="Users that have recommended same business"
          control={
            <Checkbox
              checked={usersThatHaveRecommendedSameBusiness}
              onChange={(e: any) => {
                setUserThatHaveRecommendedSameBusiness(!usersThatHaveRecommendedSameBusiness);
              }}
            />
          }
        />
      </div>
      <div>
      <FormControlLabel
        label="Users that have shared referral link"
        control={
          <Checkbox
            checked={hasSharedReferralLink}
            onChange={(e: any) => {
              setHasSharedReferralLink(!hasSharedReferralLink);
            }}
          />
        }
      />
    </div>
      <div>
        <TextField
          label="Users that have visited page"
          fullWidth
          value={visitedPage}
          onChange={(event: any) => {
            setVisitedPage(event.target.value ?? '');
          }}
        />
      </div>
      <div>
        Operator among conditions:
        <Select
        value={operator}
        onChange={(event: any) => setOperator(event.target.value)}
      >
        <MenuItem value={'AND'}>AND</MenuItem>
        <MenuItem value={'OR'}>OR</MenuItem>
      </Select>
      </div>
      <div>
        <Button variant="contained" onClick={handleFilter}>Filter</Button>
      </div>
    </div>
  );
};


export interface FilterAutocompleteProps {
  value: { displayName: string, name: string }
  onChange: (value: { displayName: string, name: string }) => void,
  query: DocumentNode,
  noOptionsText: string,
  label: string,
  optionExtractor: (data: any) => Array<any>;
}

export const FilterAutocomplete: React.FC<FilterAutocompleteProps> = (props: FilterAutocompleteProps) : JSX.Element => {
  const [inputValue, setInputValue] = useState('');
  const [getOptions, { data, loading }] = useLazyQuery(props.query);
  const [options, setOptions] = useState<any>([]);

  const fetch = useMemo(() => debounce((filter: string) => {
    getOptions({
      variables: {
        filter: !!filter ? filter : null,
      }
    });
  }, 400), [getOptions]);

  useEffect(() => {
    fetch(inputValue);
  }, [fetch, inputValue]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (data) {
      setOptions(props.optionExtractor(data));
    }
  }, [data, loading, props, props.optionExtractor]);

  return (
    <Autocomplete
        options={options}
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        noOptionsText={props.noOptionsText}
        value={props.value}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event: any, newValue) => {
            props.onChange(newValue ?? { name: '', displayName: '' });
        }}
        getOptionLabel={(option: any) => option.displayName}
        renderInput={(params) => (
          <TextField {...params} label={props.label} fullWidth />
        )}
        renderOption={(props: any, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    <Box>
                      {option.displayName}
                    </Box>

                </Grid>
              </Grid>
            </li>
          );
        }}
      />
  );
}